import { useEffect, useState } from 'react'
import { getItemFromStorage } from '../../scripts/localStorage'
import classes from './AllLocations.module.css'

const LocationsCounter = () => {
    const [text, setText ] = useState(null)
    const storage = getItemFromStorage('allLocations')   

    useEffect(() => {
        if (storage && storage.all) {
            setText(storage.all.length)
        }
    } ,[])

    return <>
        <span className={classes.locations_counter}><span className={`${classes.store_number} ${classes.store_number_header}`}>{text}</span> stores in United States.</span>
    </>
}

export default LocationsCounter

// `${loading ? '...' : locNumber} stores in United States.`