import { getLocationsByLatLng } from "../../scripts/fetchApi";
// import AutocompleteComponent from "./AutocompleteComponent";
import { useEffect, useState } from "react";
import NoLocationIcon from "../../icons/nolocationicon";


import classes from './PickupPannel.module.css'
import Location from "./Location";
// import EditPenIcon from "../../icons/editpenicon";
// import MapRadar from "./MapRadar";
// import GetCurrentLocation from "./GetCurrentLocation";
import { useDispatch } from "react-redux";
import { variousActions } from "../../store/various";
import { locationActions } from "../../store/location";
import GetCurrentLocationButton from "./GetCurrentLocationButton";
import AutocompleteComponentExtra from "./AutocompleteComponentExtra";
import GoToLocationsDirectory from "./GoToLocationsDirectory";


const PickupPannel = () => {
    const dispatch = useDispatch()
    
    const [noLocation, setNoLocation] = useState(false)
    const [foundLocations, setFoundLocations] = useState(null)
    const [searchedLocations, setSearchedLocation] = useState(null)
    const [loading, setLoading] = useState(false)

    


    const selectedResultHandler = async (address) => {
        if (address) {
            setLoading(true)
            setSearchedLocation(address.formattedAddress)
            const loc = await getLocationsByLatLng({
                lat: address.latitude,
                lng: address.longitude
            }, 'pickup')

            if (loc.message) {
                setNoLocation(true)
                setFoundLocations(null)
                dispatch(locationActions.setFoundLocations(null))
            } else {
                setFoundLocations(loc)
                setNoLocation(false)
                dispatch(locationActions.setFoundLocations(loc))
            }

            setLoading(false)
        } else {
            dispatch(variousActions.setError({message: 'Please select an address'}))
        }
    }

    const changeLocation = () => {
        setFoundLocations(null)
        setNoLocation(false)
        dispatch(locationActions.setFoundLocations(null))
        // setShowMap(false)
        // setFlag(prevState => prevState = !prevState)
        // dispatch(locationActions.setOrderType('asap'))
    }

    const setFoundLocationsHandler = (loc) => {
        setFoundLocations(loc)
        dispatch(locationActions.setFoundLocations(loc))
    }

    

    useEffect(() => {
        return () => {
            dispatch(locationActions.setFoundLocations(null))
            dispatch(locationActions.setMapSelectedLocation({
                id: 0,
                scroll: false
            }))
        }
    }, [])



    return (
        <>
            {/* initial state  */}
            <AutocompleteComponentExtra selectedResult={selectedResultHandler} loading={loading} hasLocations={foundLocations} label={'Search by City, Zip or Address'}/>
            {!foundLocations && <div className="form-controller">
                
                {/* <AutocompleteComponent selectedResult={selectedResultHandler} loading={loading} foundLocations={setFoundLocationsHandler} label={'Search by City or Zip Code'}/> */}
                <div className={classes.helper}>
                    {/* no location found  */}
                    {noLocation && <div className={classes.no_location_found}>
                        <NoLocationIcon /> 
                        <div className={classes.no_location_found_text}>There are no stores in your area yet!</div> 
                        <div className={classes.link_container}>
                            <GoToLocationsDirectory />
                        </div> 
                    </div>}

                    {!noLocation && <>
                        <p>To find the closest store to your location, enter city or zip code in the field above.</p>
                        <div className={classes.link_container}>
                            <GoToLocationsDirectory />
                        </div>
                    </>}

                    <div className={classes.helper_footer}>
                        <p>Tap the locator button below to find the closest store to you.</p>
                        {/* <GetCurrentLocation deliveryType={'pickup'} foundLocations={setFoundLocationsHandler} noLocation={() => {setNoLocation(true)}}/> */}
                        <GetCurrentLocationButton deliveryType={'pickup'} foundLocations={setFoundLocationsHandler} noLocation={() => {setNoLocation(true)}}/>
                    </div>
                </div>
            </div>}


            

            {/* locations have been found  */}
            {/* {foundLocations && <>
                <p className={classes.current_location_address}>Locations near <span>{searchedLocations}</span></p>
                <button className={`btn-underline btn-underline-red btn-underline-static`} onClick={changeLocation}>                      
                    <span>Change Location</span>
                    <EditPenIcon />
                </button>
            </>} */}

            {foundLocations && <div className={classes.location_container}>
                <div className={classes.location_container_counter}>
                    {foundLocations.length} Location{foundLocations.length > 1 ? 's' : ''} Nearby
                </div>
                {foundLocations.map((item, index) => (
                    <Location key={item.id} itemIndex={index} data={item} deliveryType={'pickup'}/> 
                ))}    
            </div>}
        </>
    );
};

export default PickupPannel;
