import { useEffect, useState } from 'react'
import { getItemFromStorage } from '../../scripts/localStorage'
import classes from './AllLocations.module.css'
import { useParams } from 'react-router-dom'
import { stateLabelValues } from '../../scripts/helpers'

const LocationsCounterCity = () => {
    const [text, setText ] = useState(null)
    const [currentCity, setCurrentCity] = useState(null)

    const storage = getItemFromStorage('allLocations')   

    const params = useParams()

    useEffect(() => {
        if (storage && storage.all) {
            if (params && params.stateName  && params.cityName) {
                setCurrentCity(params.cityName.split("_").join(' '))


                let cityStore = storage.all.filter(item => item.address.city.toLowerCase() === params.cityName.split('_').join(" "))
                if (cityStore) {
                    setText(cityStore.length)
                }
            }
        }
    } ,[params])

    return <>
        <span className={classes.locations_counter}><span className={`${classes.store_number} ${classes.store_number_header}`}>{text}</span> stores in <span className={classes.city_name}>{currentCity}</span>.</span>
    </>
}

export default LocationsCounterCity

// `${loading ? '...' : locNumber} stores in United States.`