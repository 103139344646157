import { useRef, useState } from "react"
import { GetUserViaPunchMobileAPI, registerUser, RequestMigration } from "../../scripts/fetchApi";
import { useDispatch } from "react-redux";
import { variousActions } from "../../store/various";
import { formatPhone, isEmail, isEmpty, isSame } from "../../scripts/helpers";
import { authActions } from "../../store/auth";
import { getuserInfo, getCartById } from "../../scripts/fetchApi";
import { getItemFromSessionStorage, setSessionStorageItem } from "../../scripts/localStorage";

import classes from './Authentication.module.css'

import { useNavigate } from "react-router-dom";


const MigrateAccount = ({position, btnText, btnClass}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)


    const emailInputRef = useRef(null)
    const phoneInputRef = useRef(null)
    const firstNameInputRef = useRef(null)
    const lastNameInputRef = useRef(null)
    const passwordInputRef = useRef(null)
    const confirmedPasswordInputRef = useRef(null)
    const checkBoxRef = useRef(null)
   

    const [formInputsValidity, setFormInputsValidity] = useState({
        email: true,
        phone: true,
        firstName: true,
        lastName: true,
        password: true,
        matchPassword: true,
        checkBoxRef: true
    })

    const signUpHandler = async (event) => {
        event.preventDefault()
        

        const enteredEmail = emailInputRef.current.value
        const enteredPhone = phoneInputRef.current.value
        const enteredFirstName = firstNameInputRef.current.value
        const enteredLastName = lastNameInputRef.current.value
        const enteredPassword = passwordInputRef.current.value
        const enteredConfirmedPassword = confirmedPasswordInputRef.current.value
        const terms = checkBoxRef.current.checked
   

        const enteredEmailisValid = !isEmpty(enteredEmail) && isEmail(enteredEmail)
        const enteredPhoneIsValid = !isEmpty(enteredPhone)
        const enteredFirstNameIsValid = !isEmpty(enteredFirstName)
        const enteredLastNameIsValid = !isEmpty(enteredLastName)

        const enteredPasswordisValid = !isEmpty(enteredPassword) && !isEmpty(enteredConfirmedPassword)

        const passwordMatch = isSame(enteredPassword, enteredConfirmedPassword)

        setFormInputsValidity({
            email: enteredEmailisValid,
            phone: enteredPhoneIsValid,
            firstName: enteredFirstNameIsValid,
            lastName: enteredLastNameIsValid,
            password: enteredPasswordisValid,
            matchPassword: passwordMatch,
            checkBoxRef: terms
        })

        const formIsValid = enteredEmailisValid && enteredPhoneIsValid && enteredFirstNameIsValid && enteredLastNameIsValid && enteredPasswordisValid && passwordMatch && terms

        if (formIsValid) {
            setIsLoading(true)
            const reqData = {
                email: enteredEmail,
                phone: enteredPhone,
                first_name: enteredFirstName,
                last_name: enteredLastName,
                password: enteredPassword
            }

            // const cart = getItemFromStorage('currentCart')
            const cart = getItemFromSessionStorage('currentCart')
            const login = await registerUser(reqData)

            if (login.message) {
                dispatch(variousActions.setError(login))
            } else {


                dispatch(authActions.login(login))
                console.log('login::::: ', login)
                const migrateUser = await GetUserViaPunchMobileAPI(login.authtoken , {
                    access_token: login.access_token
                })

        
                
                

                const user = await getuserInfo(login.authtoken)
                
                dispatch(authActions.setUser(user))
                if (cart) {
                    const getCart = await getCartById(cart.id, login.authtoken) // this apparently gets the current cartand if it exists it adds it to the logged in user? TODO / TOCHECK

                    if (getCart.id) {
                        // setStorageItem('currentCart', getCart)
                        setSessionStorageItem('currentCart', getCart)
                    }
                }

                if (migrateUser.message) {
                    console.log('there was an error::::: ', migrateUser)
                } else {
                    
                    if (migrateUser.user.migrate_status) {
                        navigate('/')
                    } else {
                        let migrate = await RequestMigration(login.authtoken, {
                            access_token: login.access_token
                        })
    
                        if (migrate.message) {
                            dispatch(variousActions.setError(migrate))
                        } else {
                            navigate('token')
                        }
                    }                    

                }

                
            }
            setIsLoading(false)
        } 

    }

    const [phoneValue, setPhoneValue] = useState('')

    const phoneFormatHandler = (e) => {    
        setPhoneValue(formatPhone(e.target.value))
    }

    return <>
        <div className={classes.intro}>
            <p>We've combined Great American Cookies & Marble Slab Creamery Rewards! You can now earn points and redeem rewards at your favorite Great American Cookies and Marble Slab Creamery locations.</p>
            <p>If you have an existing Great American Cookies Rewards account, please proceed to log in to the new app or at online checkout. If you have an existing Marble Slab Creamery Rewards account, please complete the migration form to migrate your existing points.*</p>
        </div>
        <form onSubmit={signUpHandler} className='form-fields-container'>       
            <div className="form-controller">
                <label className="label" htmlFor="email_input">Email</label>
                <input type="email" id="email_input" placeholder="Email" required ref={emailInputRef} className={`${!formInputsValidity.email ? 'error' : null}`} />
                {!formInputsValidity.email && <div className='error-message'>Please enter a valid email!</div>}
            </div>
            <div className="form-controller">
                <label className="label" htmlFor="phone_input">Phone</label>
                <input type="text" id="phone_input" value={phoneValue} placeholder="Phone" required ref={phoneInputRef} className={`${!formInputsValidity.phone ? 'error' : null}`} onChange={phoneFormatHandler}  /> 
                {!formInputsValidity.phone && <div className='error-message'>Please enter a phone number!</div>}
            </div>
            <div className="form-controller-group">
                <div className="form-controller">
                    <label className="label" htmlFor="first_name_input">First Name</label>
                    <input type="text" id="first_name_input" placeholder="First Name" required ref={firstNameInputRef} className={`${!formInputsValidity.firstName ? 'error' : null}`} />
                    {!formInputsValidity.firstName && <div className='error-message'>Please enter a name!</div>}
                </div>
                <div className="form-controller">
                    <label className="label" htmlFor="last_name_input">Last Name</label>
                    <input type="text" id="last_name_input" placeholder="Last Name" required ref={lastNameInputRef} className={`${!formInputsValidity.lastName ? 'error' : null}`} />
                    {!formInputsValidity.lastName && <div className='error-message'>Please enter a name!</div>}
                </div>
            </div>
            <div className="form-controller-group">
                <div className="form-controller">
                    <label className="label" htmlFor="password_input">Password</label>
                    <input id="password_input" type="password" required minLength={8} ref={passwordInputRef} className={`${!formInputsValidity.password ? 'error' : null}`} />
                    {!formInputsValidity.password && <div className='error-message'>Please enter a password!</div>}
                </div>
                <div className="form-controller">
                    <label className="label" htmlFor="confirm_password_input">Confirm Password</label>
                    <input id="confirm_password_input" type="password" minLength={8} required ref={confirmedPasswordInputRef} className={`${!formInputsValidity.matchPassword ? 'error' : null}`}/>
                    {!formInputsValidity.matchPassword && <div className='error-message'>Passwords don not match!</div>}
                </div>
            </div>
            <div className="form-controller form-group-checkbox">
                <input ref={checkBoxRef} type="checkbox" id="terms_and_conditions" name="terms_and_conditions"  />
                <label htmlFor="terms_and_conditions">I agree to the Great American Cookies & Marble Slab Creamery Rewards <a href="https://greatamericancookies.com/rewards-terms/" target="_blank" rel="noreferrer" className={classes.terms_link}>Terms and Conditions</a></label>
                {!formInputsValidity.checkBoxRef && <div className='error-message'>You need to agree to the terms and conditions!</div>}
            </div>
            <div className={`form-controller`}>
                {!isLoading && <button className={`btn ${btnClass ? btnClass : ''}`} type="submit">{btnText ? btnText : 'Sign up'}</button>}
                {isLoading && <div className="btn"><span className="loader"></span></div>}
            </div>
        </form>

        <div className={classes.fine_print}>
            <p>*Existing Marble Slab Creamery Rewards members are eligible to migrate their existing points earned through 9/3/24 11:59 pm EST to the combined Great American Cookies and Marble Slab Creamery Rewards program. Previously earned Marble Slab Creamery rewards (birthday, anniversary, etc.) are not eligible for migration. Earned $5 OFF rewards for reaching 50 points are eligible for migration.</p>
        </div>
    </>
}

export default MigrateAccount