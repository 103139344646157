import { Suspense } from "react"
import BannerSimpleSet from "../components/common/BannerSimpleSet"

import { Await, useLoaderData } from "react-router-dom"
import LoadingWrapper from "../components/common/LoadingWrapper"


import classes from './Error.module.css'
import PageWrapper from "../components/common/PageWrapper"


import StatesLocations from "../components/common/StatesLocations"
import LocationsCounterState from "../components/common/LocationsCounterState"

const DirectoryState = () => {    

    const data = useLoaderData() 

    return <Suspense fallback={<LoadingWrapper text=""/>}>
        <Await resolve={data.data} errorElement={<div className="container-full">
                <section className={classes.error}>
                    <h1 className="text-center">Page Not Found</h1>
                    <h3 className="text-center">No cookies for you here :( !</h3>
                </section>
            </div>}>
            {() => <>
                
                <div className="overflow_container">
                    <BannerSimpleSet title={'Dessert Near Me'} subtitle={<LocationsCounterState />} supTitle={'our locations'} type={'CNC'}/>
                    <StatesLocations />
                    
                </div>
            </>}
        </Await>
    </Suspense>
}

export default DirectoryState