

import { defer, useLoaderData, Await } from "react-router-dom"
import { Suspense } from "react"
import { getMenuFromLocationId, getProductFromID} from "../scripts/fetchApi"
import { getItemFromStorage, setStorageItem } from "../scripts/localStorage"
import SuperCategories from "../components/products/SuperCategories"
import LoadingWrapper from "../components/common/LoadingWrapper"

import classes from './Error.module.css'
import PageWrapper from "../components/common/PageWrapper"
import { GACTreatwareRedirect, isMSCChangeStyle, setCookie } from "../scripts/helpers"
import BannerSimple from "../components/common/BannerSimple"
import NoOnlineOrdering from "../components/common/NoOnlineOrdering"


const LocationPage = () => {

    const {data} = useLoaderData()     
    console.log('data::::: ', data)
    return <Suspense fallback={<LoadingWrapper text=""/>}>
        <Await resolve={data} errorElement={<div className="container-full">
                <section className={classes.error}>
                    <h1 className="text-center">Page Not Found</h1>
                    <h3 className="text-center">No cookies for you here :( !</h3>
                </section>
            </div>}>
            {(data) => <>
                <PageWrapper>
                    <div className="overflow_container">
                        <BannerSimple />
                        <SuperCategories />
                    </div>
                </PageWrapper>
            </>}
        </Await>
    </Suspense>
}

export default LocationPage

async function loadData(slug, prodID) {

    // if (window.location.href.includes("marble-slab-creamery")) {
    //     const trail = window.location.href.split("marble-slab-creamery").pop()
    //     window.location.href = 'https://msc--fatbrands-gac.netlify.app/menu' + trail
    // }

    const storage = getItemFromStorage('currentLocation') 

    let params = new URL(document.location).searchParams;
    let rwg_token = params.get("rwg_token");
    if (rwg_token) {
        setCookie('rwg_token', rwg_token, 30)
    }

    const fetchLocation = async () => {

        const loc = await getMenuFromLocationId(slug)

        if (!loc.id) {
            throw new Response("Not Found", { status: 404 });
        } else {

            //GAC Treatware functionality
            GACTreatwareRedirect(loc.data, loc.slug)
            
            setStorageItem('currentLocationId', {
                currentLocationId: loc.id,
                // currentLocationSlug: loc.slug
                currentLocationSlug: loc.location_slug
            }, null) 

            setStorageItem('currentMenu', {
                // menu: menu
                menu: loc
            }, 3600) 
            setStorageItem('currentLocation', {
                currentLocation: loc,
                deliveryType: 'pickup',
            }, 3600) 

            if (prodID !== null) {
                const product = await getProductFromID(loc.id, prodID)
    
    
                if (product.id) {
                    setStorageItem('foundProduct', {
                        product : product
                    })
        
                    return {menu: loc, prodDetails: product}
                } else {
                    throw new Response("Not Found", { status: 404 });
                }
            }
            console.log('loc::::: ', loc) 
            return loc
        }
        
    }

    
    if (storage === null) {    // there is nothing in storage = search location 
        const menu = fetchLocation()
        
        return menu
    } else {  // there is a location 
        if (storage.currentLocation.location_slug !== slug) { // the location in storage does not match the slug   // old if --> storage.currentLocation.slug !== slug         
            sessionStorage.removeItem('wasWarningShown')
            const menu = await fetchLocation()   
                         
            return menu
        }


        //GAC Treatware functionality
        GACTreatwareRedirect(storage.currentLocation.data, storage.currentLocation.slug)
        if (prodID !== null) {
            const product = await getProductFromID(getItemFromStorage('currentMenu').menu.id, prodID)

            if (!product.id) {
                throw new Response("Not Found", { status: 404 });
            } else {
                setStorageItem('foundProduct', {
                    product : product
                })
    
                return {
                    menu: getItemFromStorage('currentMenu').menu, 
                    prodDetails: product
                }
            }            
        }


        // START change css for MSC locations
        isMSCChangeStyle(storage)
        // END change css for MSC locations

        return {
            menu: getItemFromStorage('currentMenu').menu
        }
    }
}

export async function loader({ request, params}) {
    const slug = params.locationSlug
    const prodID = params.productSlug || null
    
    return defer({
        data: loadData(slug, prodID)
    })    
}

