import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { getDeliveryTimeslots } from '../../scripts/fetchApi'

import classes from './DateTimeAdvanced.module.css'
import { variousActions } from "../../store/various"
import PenIcon from "../../icons/penicon"
import ConfirmSelection from "./ConfirmSelection"
import { formatDate, formatOLODate, formatOLOTime } from "../../scripts/helpers"

const DateTimeAdvanced = ({isCurbside, isDelivery , setDate, setTime}) => {
    const dispatch = useDispatch()
    const [showContinueButton, setShowContinueButton] = useState(false)
    const [days, setDays] = useState(null)
    const [timeSlots, setTimeSlots] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)
    const [isAsap, setIsAsap] = useState(true)


    const cart = useSelector(state => state.cart.cart)

    const location = useSelector(state => state.location.location) 
    const [timeSlotsLoading, setTimeSlotsloading] = useState(false)
    const dayRef = useRef()
    const timeRef = useRef()

    useEffect(() => {
        const dates = []
        let limitDay = null
        if (cart && cart.earliestreadytime) {
            limitDay = formatOLODate(cart.earliestreadytime)
        }
        
        for (let index = 0; index < location.services.advance_days; index++) {
            const today = new Date(limitDay);
            const day = today.setDate(today.getDate() + index)
            dates.push({
                weekDay: new Date(day).toLocaleDateString('en-US', {
                    weekday: 'long',
                }),
                day: new Date(day).toLocaleDateString('en-US', {
                    day: 'numeric'
                }),
                // date: (new Date(day)).toISOString().split('T')[0],
                date: formatDate(day),
                dateUS: new Date(day).toLocaleDateString('en-US'),
                fancyFormat: new Date(day).toLocaleDateString('en-US', {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric'
                }),
            })     
        }

        setDays(dates)

        if (cart && cart.timewanted) {
            setIsAsap(false)
            updateTime(cart.timewanted)
            setShowContinueButton(true)
        }

    }, [location])

    const updateTime = async (date) => {
        setTimeSlotsloading(true)
        setTimeSlots(null)

        setSelectedDate(formatOLODate(date))
        if (isDelivery) {
            setDate(formatOLODate(date))
            setTime(formatOLOTime(cart.timewanted))
        }
        
        const timeSlots = await getDeliveryTimeslots(cart.id, location.id, date)

        let slotsToRemove = 0
        if (cart && cart.leadtimeestimateminutes) {
            slotsToRemove = Math.ceil(cart.leadtimeestimateminutes/15)
            if (slotsToRemove > 16) {
                slotsToRemove = 0
            }
        }
        if (timeSlots.status === 200) {          
            let timeSlotsArr = []
            for (const [key, value] of Object.entries(timeSlots.data)) {               
                    timeSlotsArr.push({
                    label: key,
                    value: value
                })
            }
            timeSlotsArr.splice(0, slotsToRemove);

            setTimeSlots(timeSlotsArr)

            setSelectedTime(formatOLOTime(cart.timewanted))
            setShowContinueButton(true)
            
        } else {
            dispatch(variousActions.setError(timeSlots))
            
        }
        
        setTimeSlotsloading(false)
    }


    const changeDateHandler = async (event) => {
        setTimeSlotsloading(true)
        setTimeSlots(null)

        setSelectedDate(event.target.value)

        if (isDelivery) {
            setDate(event.target.value)
        }
        
        const timeSlots = await getDeliveryTimeslots(cart.id, location.id, event.target.value)


        console.log('timeSlots::: ', timeSlots)
        let slotsToRemove = 0
        if (cart && cart.leadtimeestimateminutes) {            
            slotsToRemove = Math.ceil(cart.leadtimeestimateminutes/15)
            if (slotsToRemove > 16) {
                slotsToRemove = 0
            }
        }
        if (timeSlots.status === 200) {          
            let timeSlotsArr = []
            for (const [key, value] of Object.entries(timeSlots.data)) {               
                    timeSlotsArr.push({
                    label: key,
                    value: value
                })
            }
            timeSlotsArr.splice(0, slotsToRemove);
            setTimeSlots(timeSlotsArr)
            setSelectedTime(null)
            setShowContinueButton(false)
            if (isDelivery) {
                setTime(null)
            }
           
            
        } else {
            dispatch(variousActions.setError(timeSlots))
            
        }
        setTimeSlotsloading(false)
    }

    const selectTimeHandler = (event) => {
        setShowContinueButton(true)
        setSelectedTime(event.target.value)
        if (isDelivery) {
            setTime(event.target.value)
        }
    }

    return <div className={classes.date_time_container}>
        <div className="label-appearance">
            {isAsap && <>Select date and time</>}
            {!isAsap && <>Edit date and time</>}
        </div>
        <div className={classes.selects_container}>
            {/* no timewanted -> not scheduled  */}
            {!cart.timewanted && <>
                <div className={classes.time_slots_container}>
                    {days && <select ref={dayRef} onChange={changeDateHandler} className={`select ${classes.select}`} defaultValue={'Date'}>
                        <option disabled value="Date" hidden>Date</option>
                        {days.map(item => (                
                            <option key={item.date} value={item.date} >
                                {/* {item.weekDay} {item.day} */}
                                {item.fancyFormat}
                            </option>
                        ))}    
                    </select>}
                    <span className={classes.open_drawer}>
                        <PenIcon />
                    </span>
                </div>
                <div className={`${classes.time_slots_container} ${!timeSlots ? classes.disabled : ''}`}>
                    <select ref={timeRef} className={`select ${classes.select}`} onChange={selectTimeHandler} disabled={!timeSlots} defaultValue={'Time'}>
                        <option disabled value="Time" hidden>Time</option>
                        {timeSlots && !timeSlotsLoading && <>
                            {timeSlots.map(item => (
                                <option key={item.value} value={item.value}>{item.label}</option>
                            ))} 
                        </>}               
                    </select>
                    <span className={classes.open_drawer}>
                        <PenIcon fill="#fff"/>
                    </span>
                    {!timeSlots && timeSlotsLoading && <div className={classes.loading_overlay}><span className="loader dark"></span></div>}
                </div>
            </>}
            {cart.timewanted && <>
                <div className={classes.time_slots_container}>
                    {days && <select ref={dayRef} onChange={changeDateHandler} className={`select ${classes.select}`} defaultValue={formatOLODate(cart.timewanted)}>
                        <option disabled value="Date" hidden>Date</option>
                        {days.map(item => (                
                            <option key={item.date} value={item.date} >
                                {/* {item.weekDay} {item.day} */}
                                {item.fancyFormat}
                            </option>
                        ))}    
                    </select>}
                    <span className={classes.open_drawer}>
                        <PenIcon />
                    </span>
                </div>
                <div className={`${classes.time_slots_container} ${!timeSlots ? classes.disabled : ''}`}>
                    <select ref={timeRef} className={`select ${classes.select}`} onChange={selectTimeHandler} disabled={!timeSlots} value={selectedTime ? selectedTime : 'Time'}>
                        <option disabled value="Time" hidden>Time</option>
                        {timeSlots && !timeSlotsLoading && <>
                            {timeSlots.map(item => (
                                <option key={item.value} value={item.value}>{item.label}</option>
                            ))} 
                        </>}               
                    </select>
                    <span className={classes.open_drawer}>
                        <PenIcon fill="#fff"/>
                    </span>
                    {!timeSlots && timeSlotsLoading && <div className={classes.loading_overlay}><span className="loader dark"></span></div>}
                </div>
            </>}
            
            
        </div>
        {/* {showContinueButton && <div className={classes.controls}>
            {!loader && <button onClick={setScheduledDeliveryHandler} className="btn">Confirm</button>}
            {loader && <div className="btn"><span className="loader"></span></div>}
        </div>} */}
        {showContinueButton && !isDelivery && <ConfirmSelection timemode={'schedule'} date={selectedDate} time={selectedTime} isCurbside={isCurbside}/>}
    </div>
}

export default DateTimeAdvanced