import { useEffect, useState } from "react"
import { getALLLocations } from "../../scripts/fetchApi"


import { Link } from "react-router-dom"
import { getItemFromStorage } from "../../scripts/localStorage"
import { stateLabelValues } from "../../scripts/helpers"
import ArrowSliderRightIcon from "../../icons/arrowsliderright"

import classes from './AllLocations.module.css'

const AllLocations = () => {
    const [locations, setLocations] = useState(null)
    const storage = getItemFromStorage('allLocations')   


    useEffect(() => {
        if (storage && storage.all) {
            let arr = []
            storage.all.forEach(element => {
                if (element.address && element.address.state) {
                    const index = arr.findIndex(object => {
                        return object.state === element.address.state
                    })
                    
                    if (index === -1) {
                        arr.push({
                            state: element.address.state,
                            stateFull: null,
                            locations: []
                        })
                    }
                    
                }
            });

            storage.all.forEach(element => {
                arr.forEach(item => {
                    if (item.state === element.address.state) {
                        item.locations.push(element)
                    }
                })
            })

            arr.forEach((element) => {
                stateLabelValues?.forEach(item => {
                    if (item.value === element.state) {
                        element.stateFull = item.label
                    }
                })
            })
            
            arr.sort(function (a, b) {
                return a.stateFull.localeCompare(b.stateFull);
            })
            setLocations(arr)
        }
    } , [])

    return <div className={classes.container_wrapper}>
        <div className={classes.container}>
            {/* <div className={classes.subtitle}>discover all</div> */}
            <h2 className={classes.title}>All Locations</h2>

            {locations && <div className="grid-3 gap_24">
                {locations.map(item => (
                    <Link to={item.state.toLowerCase()} key={item.state} className={classes.item}>
                        <div><span className={classes.state}>{item.stateFull}</span> <span className={classes.store_number}>({item.locations.length})</span></div>
                        <ArrowSliderRightIcon />
                    </Link>
                ))}    
            </div>}
        </div>
    </div>
}

export default AllLocations