import { useEffect, useState } from 'react'
import { getItemFromStorage } from '../../scripts/localStorage'
import classes from './AllLocations.module.css'
import { useParams } from 'react-router-dom'
import { stateLabelValues } from '../../scripts/helpers'

const LocationsCounterState = () => {
    const [text, setText ] = useState(null)
    const [currentState, setCurrentState] = useState(null)

    const storage = getItemFromStorage('allLocations')   

    const params = useParams()

    useEffect(() => {
        if (storage && storage.all) {
            if (params && params.stateName) {
                stateLabelValues?.forEach(item => {
                    if (item.value.toLowerCase() === params.stateName) {
                        setCurrentState(item.label)
                    }
                })


                let stateStore = storage.all.filter(item => item.address.state.toLowerCase() === params.stateName)
                if (stateStore) {
                    setText(stateStore.length)
                }
            }
        }
    } ,[params])

    return <>
        <span className={classes.locations_counter}><span className={`${classes.store_number} ${classes.store_number_header}`}>{text}</span> stores in {currentState}.</span>
    </>
}

export default LocationsCounterState

// `${loading ? '...' : locNumber} stores in United States.`